import { MODAL_ACTION_TYPES } from '../constants/actionTypes/modal';
import { initialState } from './filters';

// initial state closed
const MODAL_INITIAL_STATE = {
  isOpen: false,
  currentCategory: '',
  modalFilters: initialState,
};

const modalReducer = (
  state = MODAL_INITIAL_STATE,
  action = {
    type: '',
    payload: { category: '' },
  },
) => {
  const { type, payload } = action;
  const {
    SET_MODAL_CATEGORY, TOGGLE_MODAL, OPEN_MODAL_TO_CATEGORY, UPDATE_MODAL_FILTERS, CLEAR_ALL_MODAL_FILTERS,
  } = MODAL_ACTION_TYPES;

  switch (type) {
    case SET_MODAL_CATEGORY:
      return { ...state, currentCategory: payload.category };

    case TOGGLE_MODAL:
      return { ...state, isOpen: !state.isOpen };

    case OPEN_MODAL_TO_CATEGORY:
    {
      // Pinning selected category to top
      const sortedFilters = Object.keys(state.modalFilters)?.sort(
        filterKey => (state.modalFilters[filterKey]?.category === payload.category ? -1 : 1),
      )?.reduce(
        (obj, key) => {
          // eslint-disable-next-line no-param-reassign
          obj[key] = state.modalFilters[key];
          return obj;
        },
        {},
      );

      return {
        ...state,
        modalFilters: sortedFilters,
        currentCategory: payload.category,
        isOpen: !state.isOpen,
      };
    }

    case UPDATE_MODAL_FILTERS: {
      const updatedKeys = {};

      Object.keys(action.payload).forEach((category) => {
        updatedKeys[category] = {
          ...state.modalFilters[category],
          options: {
            // ...state.modalFilters[category]?.options -> Avoid displaying options that would yield no results
            ...action.payload[category]?.options,
          },
        };
      });

      return {
        ...state,
        modalFilters: {
          ...state.modalFilters,
          ...updatedKeys,
        },
      };
    }

    case CLEAR_ALL_MODAL_FILTERS:
    {
      // set all options to false
      const updatedKeys = {};
      Object.keys(state.modalFilters).forEach((category) => {
        const updatedOptions = {};
        Object.keys(state.modalFilters[category].options).forEach((option) => {
          updatedOptions[option] = {
            ...state.modalFilters[category].options[option],
            selected: false,
          };
        });
        updatedKeys[category] = {
          ...state.modalFilters[category],
          options: updatedOptions,
          numOptionsSelected: 0,
        };
      });

      return {
        ...state,
        modalFilters: {
          ...state.modalFilters,
          ...updatedKeys,
        },
      };
    }

    default:
      return state;
  }
};

export default modalReducer;
