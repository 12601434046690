import { SET_DEGREE_PAGE_TAB, SET_DEGREE_TAB_SECTIONS } from '@data/constants/actionTypes/degreePage';

const initialState = {
  activeTab: '',
  tabSections: [],
};

const degreePage = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_DEGREE_PAGE_TAB:
      return {
        ...state,
        activeTab: action.value,
      };

    case SET_DEGREE_TAB_SECTIONS:
      return {
        ...state,
        tabSections: action.value,
      };

    default:
      return state;
  }
};

export default degreePage;
