import { SET_AUTOCOMPLETE_PREQUERY } from '@data/constants/actionTypes/autoComplete';

const initialState = {
  preQuery: {
    default: {
      products: [],
      popularSearchTerms: [],
    },
  },
};

const autoComplete = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_AUTOCOMPLETE_PREQUERY:
      return {
        ...state,
        preQuery: action.value,
      };

    default:
      return state;
  }
};

export default autoComplete;
