import {
  LEARNER_ENTERPRISE_FETCH_ERROR,
  LEARNER_ENTERPRISE_FETCH_SUCCESS,
  LEARNER_ENTERPRISE_USER_NOT_FOUND,
} from '@data/constants/actionTypes/enterprise';

const enterpriseUser = (state = null, action = {}) => {
  switch (action.type) {
    case LEARNER_ENTERPRISE_FETCH_SUCCESS:
      return action.value;
    case LEARNER_ENTERPRISE_FETCH_ERROR:
      return false;
    case LEARNER_ENTERPRISE_USER_NOT_FOUND:
      return false;

    default:
      return state;
  }
};

export default enterpriseUser;
