import SET_ACTIVE_COURSE_RUN from '@data/constants/actionTypes/activeCourseRun';

const initialState = {};

const setActiveCourseRun = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ACTIVE_COURSE_RUN:
      return action.value;

    default:
      return state;
  }
};

export default setActiveCourseRun;
