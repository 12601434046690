import { SET_ACCORDIONS, UPDATE_ACCORDIONS } from '@data/constants/actionTypes/accordions';

const initialState = {};

const accordions = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ACCORDIONS:
      return action.value;

    case UPDATE_ACCORDIONS:
      return {
        ...state,
        [action.value.id]: action.value.isOpen,
      };

    default:
      return state;
  }
};

export default accordions;
