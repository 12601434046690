import SET_CURRENT_TYPE from '@data/constants/actionTypes/currentPage';

const initialState = 'default';

const currentPage = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_TYPE:
      return action.value;

    default:
      return state;
  }
};

export default currentPage;
