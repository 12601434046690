import {
  SET_INSTRUCTOR_DATA,
  INSTRUCTOR_DATA_ERROR,
  INSTRUCTOR_DATA_LOADING,
} from '@data/constants/actionTypes/person';
import { camelCaseObject } from '../../build-utils/helpers/fieldRenaming';

const initialState = {};
export const ERROR = 'error';
export const LOADING = 'loading';
export const SUCCESS = 'success';

const formatInstructorData = ({
  data:
  {
    count,
    results: [firstResult],
  },
}) => {
  if (count === 0) {
    throw new Error('Instructor not found');
  }
  // this should be impossible since slugs are unique
  if (count > 1) {
    throw new Error('Multiple instructors matching slug found');
  }
  return camelCaseObject(firstResult);
};

const instructorData = (state = initialState, action = {}) => {
  switch (action.type) {
    case INSTRUCTOR_DATA_ERROR:
      return {
        instructorData: {},
        instructorState: ERROR,
      };

    case INSTRUCTOR_DATA_LOADING:
      return {
        instructorData: {},
        instructorState: LOADING,
      };

    case SET_INSTRUCTOR_DATA:
      return {
        instructorData: formatInstructorData(action.value),
        instructorState: SUCCESS,
      };

    default:
      return state;
  }
};

export default instructorData;
