import {
  SET_USER_LANGUAGE,
  SET_USER_LOGGED_IN,
  USER_ENROLLMENTS_ERROR,
  USER_ENROLLMENTS_SUCCESS,
  USER_ENTERPRISE_PORTAL_LINKS_ERROR,
  USER_ENTERPRISE_PORTAL_LINKS_SUCCESS,
  USER_ENTITLEMENTS_ERROR,
  USER_ENTITLEMENTS_SUCCESS,
  SET_USER_CURRENCY, USER_ENROLLMENTS_PENDING, USER_ENTITLEMENTS_PENDING,
} from '@data/constants/actionTypes/user';

const initialState = {};

// Parse enrollment response to include just the course Id and enrollment mode
const parseEnrollments = (data) => {
  const { data: enrollments } = data;
  if (enrollments && enrollments.length) {
    return enrollments.map(({ course_details: courseDetails, mode }) => ({
      courseId: courseDetails.course_id,
      mode,
    }));
  }

  return [];
};

export const userEnrollments = (state = { ...initialState, status: 'idle' }, action = {}) => {
  switch (action.type) {
    case USER_ENROLLMENTS_ERROR:
      return {
        userEnrollments: [],
        status: 'error',
        error: true,
      };

    case USER_ENROLLMENTS_PENDING:
      return {
        ...state,
        status: 'pending',
      };

    case USER_ENROLLMENTS_SUCCESS:
      return {
        userEnrollments: parseEnrollments(action.value),
        status: 'success',
        error: false,
      };

    default:
      return state;
  }
};

export const userEnterprisePortalLinks = (state = initialState, action = {}) => {
  switch (action.type) {
    case USER_ENTERPRISE_PORTAL_LINKS_ERROR:
      return {
        userEnterprisePortalLinks: [],
        error: true,
      };

    case USER_ENTERPRISE_PORTAL_LINKS_SUCCESS:
      return {
        userEnterprisePortalLinks: action.value,
        error: false,
      };

    default:
      return state;
  }
};

// Parse entitlement response to include just the course UUID
const parseEntitlements = (data) => {
  const { data: entitlements } = data;
  if (entitlements && entitlements.results) {
    return entitlements.results.map(entitlement => ({
      uuid: entitlement.uuid,
      expirationDate: entitlement.expired_at,
      courseUuid: entitlement.course_uuid,
    }));
  }
  return [];
};

export const userEntitlements = (state = { ...initialState, status: 'idle' }, action = {}) => {
  switch (action.type) {
    case USER_ENTITLEMENTS_ERROR:
      return {
        userEntitlements: [],
        status: 'error',
        error: true,
      };

    case USER_ENTITLEMENTS_PENDING:
      return {
        ...state,
        status: 'pending',
      };

    case USER_ENTITLEMENTS_SUCCESS:
      return {
        userEntitlements: parseEntitlements(action.value),
        status: 'success',
        error: false,
      };

    default:
      return state;
  }
};

export const userLanguage = (state = false, action = {}) => {
  switch (action.type) {
    case SET_USER_LANGUAGE:
      return action.value;

    default:
      return state;
  }
};

export const userLoggedIn = (state = false, action = {}) => {
  switch (action.type) {
    case SET_USER_LOGGED_IN:
      return action.value;

    default:
      return state;
  }
};

export const userCurrency = (state = false, action = {}) => {
  switch (action.type) {
    case SET_USER_CURRENCY:
      return action.value;

    default:
      return state;
  }
};
