/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Provider } from 'react-redux';
import { flat } from 'array-flat-polyfill'; // eslint-disable-line no-unused-vars
import { shouldPolyfill } from '@formatjs/intl-locale/should-polyfill';
import { URL } from 'url-polyfill'; // eslint-disable-line no-unused-vars
import { HelmetProvider } from 'react-helmet-async';
import createStore from './src/data/store';

if (shouldPolyfill()) {
  import('@formatjs/intl-locale/polyfill'); // eslint-disable-line no-unused-vars
}

const store = createStore();

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => {
  const ConnectedRootElement = (
    <Provider store={store}>
      {/* replaces gatsby-plugin-react-helmet-async, which is not threadsafe */}
      <HelmetProvider>{element}</HelmetProvider>
    </Provider>
  );
  return ConnectedRootElement;
};

export const onInitialClientRender = () => {
  if (!window.analytics || typeof window.analytics.page !== 'function') {
    return;
  }

  window.analytics.page();

  if (window.location.pathname.indexOf('/webview') === -1) {
  /* eslint-disable */
  // branch.io sdk - creates that banner on the mobile view that prompts users to go to app
  (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);

    branch.init(process.env.GATSBY_MOBILE_BANNER_KEY);
    /* eslint-enable */
  }

  // indicate to cypress e2e tests that page is rendered
  const finishedDiv = window.document.createElement('div');
  finishedDiv.className = 'initial-client-render-finished d-none';
  window.document.body.appendChild(finishedDiv);
};
