const camelCase = require('lodash/camelCase');

// Copied from https://github.com/edx/frontend-app-payment/blob/27778942976c16713589fef2b35732c76b124894/src/common/utils.js#L4-#L24
function modifyObjectKeys(object, modify) {
  // If the passed in object is not an object, return it.
  if (
    object === undefined
    || object === null
    || (typeof object !== 'object' && !Array.isArray(object))
  ) {
    return object;
  }

  if (Array.isArray(object)) {
    return object.map(value => modifyObjectKeys(value, modify));
  }

  // Otherwise, process all its keys.
  const result = {};
  Object.entries(object).forEach(([key, value]) => {
    result[modify(key)] = modifyObjectKeys(value, modify);
  });
  return result;
}

function camelCaseObject(object) {
  return modifyObjectKeys(object, camelCase);
}

module.exports = {
  camelCaseObject,
};
