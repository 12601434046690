export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export const SET_USER_LOGGED_IN = 'SET_USER_LOGGED_IN';
export const USER_ENROLLMENTS_ERROR = 'USER_ENROLLMENTS_ERROR';
export const USER_ENROLLMENTS_SUCCESS = 'USER_ENROLLMENTS_SUCCESS';
export const USER_ENROLLMENTS_PENDING = 'USER_ENROLLMENTS_PENDING';
export const USER_ENTITLEMENTS_ERROR = 'USER_ENTITLEMENTS_ERROR';
export const USER_ENTITLEMENTS_PENDING = 'USER_ENTITLEMENTS_PENDING';
export const USER_ENTITLEMENTS_SUCCESS = 'USER_ENTITLEMENTS_SUCCESS';
export const USER_ENTERPRISE_PORTAL_LINKS_ERROR = 'USER_ENTERPRISE_PORTAL_LINKS_ERROR';
export const USER_ENTERPRISE_PORTAL_LINKS_SUCCESS = 'USER_ENTERPRISE_PORTAL_LINKS_SUCCESS';
export const SET_USER_CURRENCY = 'SET_USER_CURRENCY';
