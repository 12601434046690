import {
  ENROLL_SUBMISSION_ERROR,
  ENROLL_SUBMISSION_SUCCESS,
} from '@data/constants/actionTypes/enrollment';

const initialState = {};

const enrollment = (state = initialState, action = {}) => {
  switch (action.type) {
    case ENROLL_SUBMISSION_ERROR:
      return {
        value: action.value,
        error: true,
        enrolled: false,
      };

    case ENROLL_SUBMISSION_SUCCESS:
      return {
        value: action.value,
        error: false,
        enrolled: true,
      };

    default:
      return state;
  }
};

export default enrollment;
