import { applyMiddleware, createStore as reduxCreateStore } from 'redux';
// import { compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createLogger } from 'redux-logger';
// import { enhancer as WithReduxEnhancer } from 'addon-redux';

import reducers from './reducers';

let middleware = {};

if (process.env.GATSBY_ENVIRONMENT === 'development') {
  middleware = composeWithDevTools(applyMiddleware(thunkMiddleware, createLogger()));
} else if (process.env.STORYBOOK_IS_RUNNING === 'true') {
  middleware = applyMiddleware(thunkMiddleware, createLogger());
  // TODO: [WS-4267] uncomment
  // middleware = compose(middleware, WithReduxEnhancer);
} else {
  middleware = applyMiddleware(thunkMiddleware);
}

const createStore = () => reduxCreateStore(
  reducers,
  middleware,
);

export default createStore;
