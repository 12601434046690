import { SET_TRANSCRIPT_METADATA } from '@data/constants/actionTypes/videoPlayer';

const initialState = {};

const player = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_TRANSCRIPT_METADATA:
      return {
        transcriptData: action.value,
      };
    default:
      return state;
  }
};

export default player;
