import SET_SHOW_BOOT_CAMPS_NAV_LINK from '@data/constants/actionTypes/bootCamps';

const initialState = false;

const setShowBootCampNavLink = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SHOW_BOOT_CAMPS_NAV_LINK:
      return action.value;

    default:
      return state;
  }
};

export default setShowBootCampNavLink;
